<template>
  <div>
    <!--div class="d-flex flex-row justify-content-start align-items-center">
      <div>
        <h2>Снизьте цену за подписчика до 72%</h2>
        <p>Простое и эффективное управление рекламой у блогеров</p>
      </div>
      <b-button class="ml-3" size="lg" variant="yellow" href="/tracker/"
        >Открыть трекер рекламы</b-button
      >
    </!--div-->
    <MobilePlancsNavigation />
    <DesktopPlancsNavigation />
    <section class="reviews section">
      <div
        style="height: auto !important"
        class="heading-row heading-row--horizontal flex-wrap flex-xl-nowrap"
        :class="{ 'mode--my_reviews': filterParams && filterParams.mode === 'me' }"
      >
        <div
          class="
            d-flex
            flex-row
            justify-content-between
            align-items-start
            w-100
            mb-1 mb-xsm-3 mb-lg-2
          "
        >
          <div class="d-flex align-items-center">
            <h2 class="reviews__title section__title p-0 pl-lg-2 mb-md-1">
              {{
                filterParams && filterParams.mode === 'me' ? 'История моих отзывов' : reviewsTitle
              }}
            </h2>
            <!--b-button
              class="d-none d-llg-block ml-3"
              :to="{ name: 'reviews-pricetable' }"
              variant="outline-default"
              >Средняя цена за подписчика по темам из отзывов</!--b-button
            -->
          </div>
          <div class="d-flex">
            <template v-if="filterParams && !(filterParams.mode === 'me')">
              <subscription-button></subscription-button>
            </template>
            <b-button
              color="primary"
              class="ml-4"
              :to="{ name: 'reviews' }"
              variant="outline-default"
              >Отзывы --></b-button
            >
          </div>
        </div>
        <div
          class="
            d-flex
            flex-wrap
            justify-content-between
            d-xl-none
            w-100
            ml-lg-2 ml-lg-2 ml-xlg-2
            mt-xs-2
            row-btns__rev
          "
        >
          <div>
            <b-button
              variant="outline-default"
              class="short-btn mt-2 mt-xsm-0"
              @click="
                () => {
                  $bus.$emit('review-filter-open');
                }
              "
            >
              Фильтры
            </b-button>
            <b-button
              :disabled="!currentUser || !currentUser.liked_telegram_reviews"
              @click="
                () => {
                  if (filterParams.mode === 'liked') {
                    filterParams.mode = 'all';
                  } else {
                    filterParams.mode = 'liked';
                  }
                  $refs.revTable.pageChangeHandler(1);
                }
              "
              :variant="
                currentUser && currentUser.liked_telegram_reviews
                  ? filterParams && filterParams.mode === 'liked'
                    ? 'primary'
                    : 'outline-default'
                  : 'dark'
              "
              style="height: 33.5px !important"
              class="ml-1 ml-sxsm-2 mt-2 mt-xsm-0 px-2 px-xsm-2 ml-xsm-3 d-mmd-none"
              ><div class="d-flex flex-row align-items-center text-center">
                <img
                  v-if="!currentUser || !currentUser.liked_telegram_reviews"
                  class="mr-1"
                  style="height: 19px; width: 19px; margin-bottom: 1px"
                  src="@main/assets/img/svg/like-white-outline.svg"
                  alt=""
                /><img
                  class="mr-1"
                  style="height: 19px; width: 19px; margin-bottom: 1px"
                  v-else
                  src="@main/assets/img/svg/like.svg"
                />
                <span
                  :style="{
                    color:
                      !currentUser ||
                      !currentUser.liked_telegram_reviews ||
                      (filterParams && filterParams.mode === 'liked')
                        ? 'white'
                        : 'initial',
                  }"
                  >{{ currentUser ? currentUser.liked_telegram_reviews : 0 }}
                </span>
              </div></b-button
            >
          </div>
          <b-dropdown
            variant="outline-default"
            class="leave-review-dpdwn mt-2 mt-xsm-0"
            right
            toggle-class="short-btn"
          >
            <template #button-content>
              Оставить отзывы&nbsp;<b-icon-star-fill variant="warning" v-if="windowWidth > 600" />
            </template>
            <b-dropdown-item style="border-bottom: 1px solid #d5dfe4">
              <router-link :to="{ name: 'let-review-telegram' }"
                ><img class="rev_star" src="@main/assets/img/svg/star.svg" alt="" /> Оставить отзыв
                на блогера</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item
              @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'customers' })"
              >Ссылка для рекламодателей</b-dropdown-item
            >
            <b-dropdown-item
              @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'portfolio' })"
              >Ссылка для моего портфолио</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="rectangle-block-wrapper">
        <reviews-filter
          ref="filter"
          v-if="filterParams"
          v-model="filterParams"
          @disclosed="handleDisclose"
          @reset="reset"
          @search="
            () => {
              filterParams.page = 1;
              $refs.revTable.updateData();
            }
          "
        />
        <div
          style="max-height: 257px !important"
          class="
            d-none d-xl-flex
            rectangle-block
            only-desktop only-desktop--flex
            leave_rev-block
            flex-column
          "
        >
          <div class="column leave-review mt-1">
            <img
              src="../../assets/godfather_image.jpg"
              alt=""
              class="leave-review__image mx-auto"
            />
            <router-link :to="{ name: 'let-review-telegram' }" class="btn btn-info text-nowrap mt-3"
              >Оставить отзыв на канал</router-link
            >
          </div>
          <b-button
            :to="{ name: 'fill_statistics' }"
            class="fillstat-btn mt-4"
            variant="outline-default"
            >🚀 Заполнить статистику</b-button
          >
        </div>
      </div>
    </section>

    <div class="d-flex justify-content-end my-3" v-if="windowWidth >= 1234">
      <b-button
        variant="outline-secondary"
        class="bg-white mr-3"
        @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'customers' })"
      >
        Добавить отзывы на мой аккаунт
      </b-button>
      <b-button
        variant="outline-secondary"
        class="bg-white"
        @click="toggleGenerateBloggerLinkModal({ value: true, mode: 'portfolio' })"
      >
        Отзывы для моего портфолио</b-button
      >
    </div>
    <telegram-reviews-table
      :key="JSON.stringify($route.query)"
      v-if="filterParams"
      ref="revTable"
      @show-stat="
        (instaname) => {
          toggleStatisticCardModal({ open: true, context: { instaname } });
        }
      "
      :disclosed="disclosed"
      :blogger="$route.query.advertiser"
      v-model="filterParams"
      ><template #post-header-mobile>
        <div
          v-if="filterParams.mode === 'all'"
          style="background-color: white; border-bottom: 1px solid #d5dfe4"
          class="py-3 px-4 d-flex justify-content-between align-items-center d-md-none"
        >
          <p class="mr-3">Показывать только блогеров со статистикой и ценами</p>
          <b-form-checkbox
            @input="
              (val) => {
                $set(filterParams, 'valid_advertiser_blogger', val);
                $set(filterParams, 'page', 1);
                $refs.revTable.updateData();
              }
            "
            :checked="filterParams.valid_advertiser_blogger"
            switch
            size="lg"
          />
        </div>
        <div v-if="filterParams.mode === 'all'" class="only-mobile button-fill-stat__mobile p-3">
          <slot name="post-header"></slot>
          <b-button
            style="width: 100%"
            @click="() => $router.push('/fillstat')"
            variant="outline-default"
            >🚀 Заполнить карточку блогера</b-button
          >
        </div></template
      ><template #upper-header="{ pageChangeHandler, updateData, disableLikedBtn }">
        <div class="upper-part">
          <div class="left-part justify-content-md-start justify-content-lg-between">
            <h1 class="left-part__title rev-title d-none d-llg-inline-block">
              Показана таблица Telegram отзывов для
            </h1>
            <h2 class="left-part__title d-llg-none mr-sm-3">Таблица для</h2>
            <div class="d-flex ml-md-2 ml-lg-2">
              <b-button
                class="btn btn-rev short-btn"
                size="md"
                :variant="filterParams.customer_kind === 'blogger' ? 'primary' : 'outline-default'"
                @click="
                  () => {
                    $set(filterParams, 'customer_kind', 'blogger');
                    pageChangeHandler(1);
                  }
                "
              >
                Блогеров
              </b-button>
              <b-button
                style="max-width: none; width: auto !important"
                class="btn btn-rev short-btn"
                size="md"
                :variant="filterParams.customer_kind === 'shop' ? 'primary' : 'outline-default'"
                @click="
                  () => {
                    $set(filterParams, 'customer_kind', 'shop');
                    pageChangeHandler(1);
                  }
                "
              >
                Коммерции
              </b-button>
            </div>
            <b-input-group class="custom-search-field ml-2 ml-llg-4">
              <b-form-input
                v-model.lazy="filterParams.item_q"
                type="text"
                placeholder="Поиск (товар или услуга)"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="updateData" variant="outline-default"
                  ><b-icon icon="search"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              style="min-height: 33.5px !imporatnt"
              v-if="!disableLikedBtn"
              :disabled="!currentUser || !currentUser.liked_telegram_reviews"
              @click="
                () => {
                  if (filterParams.mode === 'liked') {
                    $set(filterParams, 'mode', 'all');
                  } else {
                    $set(filterParams, 'mode', 'liked');
                  }
                  pageChangeHandler(1);
                }
              "
              :variant="
                currentUser && currentUser.liked_telegram_reviews
                  ? filterParams.mode === 'liked'
                    ? 'primary'
                    : 'outline-default'
                  : 'dark'
              "
              class="ml-2 ml-llg-5 d-none d-mmd-block"
              ><div class="d-flex align-items-center">
                <img
                  v-if="!currentUser || !currentUser.liked_telegram_reviews"
                  class="d-inline-block mr-2"
                  src="@main/assets/img/svg/like-white-outline.svg"
                  alt=""
                /><img class="d-inline-block mr-2" v-else src="@main/assets/img/svg/like.svg" />
                <span
                  class="btn-lk-fv"
                  :style="{
                    color:
                      !currentUser ||
                      !currentUser.liked_telegram_reviews ||
                      filterParams.mode === 'liked'
                        ? 'white'
                        : 'initial',
                  }"
                  >Избранные отзывы: {{ currentUser ? currentUser.liked_telegram_reviews : 0 }}
                </span>
                <span
                  class="btn-lk-fv-com"
                  :style="{
                    color:
                      !currentUser ||
                      !currentUser.liked_telegram_reviews ||
                      filterParams.mode === 'liked'
                        ? 'white'
                        : 'initial',
                  }"
                  >{{ currentUser ? currentUser.liked_telegram_reviews : 0 }}
                </span>
              </div></b-button
            >
          </div>
          <div class="right-part only-desktop only-desktop--flex">
            <span class="gray-text">Показывать по</span>
            <div class="select-wrapper">
              <select class="select" v-model="filterParams.page_size">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </template></telegram-reviews-table
    >

    <generate-blogger-link-modal />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import reviewsApi from '@main/api/reviews';
import literal from '@main/func/literal';
import EventBus from '../../event-bus';
import '../../styles/reviews_list.scss';

const DesktopPlancsNavigation = () =>
  import('@main/components/elements/navigation/DesktopPlancsNavigation.vue');
const MobilePlancsNavigation = () =>
  import('@main/components/elements/navigation/MobilePlancsNavigation.vue');
const TelegramReviewsTable = () => import('@main/components/reviews_list/TelegramReviewsTable.vue');
const SubscriptionButton = () => import('../reusable/elements/buttons/SubscriptionButton.vue');
const ReviewsFilter = () => import('./Filter.vue');
const GenerateBloggerLinkModal = () => import('./GenerateBloggerLinkModal.vue');

export default {
  components: {
    ReviewsFilter,
    TelegramReviewsTable,
    GenerateBloggerLinkModal,
    MobilePlancsNavigation,
    DesktopPlancsNavigation,
    SubscriptionButton,
  },
  computed: {
    ...mapState(['currentUser', 'windowWidth', 'reviewsOptions']),
    reviewsTitle() {
      if (this.isMobile || !this.reviewsCount) {
        return 'Отзывы Telegram';
      }
      return `${this.reviewsCount.toLocaleString().replaceAll(',', ' ')} ${literal.declension(
        this.reviewsCount,
        'отзыв'
      )} на рекламу в Telegram`;
    },
  },

  data() {
    return {
      isMobile,
      reviewsCount: 0,
      reviewsCountInterval: null,
      filterParams: null,
      disclosed: false,
    };
  },

  methods: {
    ...mapMutations('reviewsList', ['toggleGenerateBloggerLinkModal']),
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    handleDisclose(val) {
      this.disclosed = val;
    },
    async setReviewsCount() {
      const res = await reviewsApi.telegram.fnc({ actions: ['count'] }, { moderation_status: 2 });
      this.$set(this, 'reviewsCount', res.result);
    },
    updateQueryFilter() {
      if (this.$route.query) {
        const keys = Object.keys(reviewsApi.defaultFilterParams);
        let params = {};
        if (keys) {
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            let val = this.$route.query[key];
            if (key === 'executor' && !val) {
              val = this.$route.query.advertiser;
            }
            if (val) {
              params = { ...params, [key]: val };
            }
          }
        }
        return params;
      }
    },
    async reset() {
      await this.$set(this, 'filterParams', this.lodash.cloneDeep(reviewsApi.defaultFilterParams));
      await this.$refs.revTable.updateData();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.toggleGenerateBloggerLinkModal({ value: false });
    next();
  },
  metaInfo: {
    meta: [
      {
        vmid: 'descripiton',
        name: 'descripiton',
        content:
          'Более 59 000 отзывов на рекламу со статистикой, ценами и оценкой заказчиком сотрудничества для блогеров',
      },
    ],
  },
  async mounted() {
    this.filterParams = {
      ...this.lodash.cloneDeep(reviewsApi.defaultFilterParams),
      ...this.updateQueryFilter(),
    };
    await this.setReviewsCount();
  },
  watch: {
    '$route.query': {
      deep: true,
      handler(val) {
        this.filterParams = { ...reviewsApi.defaultFilterParams, ...this.updateQueryFilter() };
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .cттustom-control-label::before {
  top: -0.019rem;
  height: 1.95rem;
  left: -2.8125rem;
  width: 3.13rem;
  border-radius: 2.725rem;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
::v-deep .custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc(-0.016rem + 2px);
  left: calc(-2.8125rem + 2px);
  width: calc(1.92rem - 4px);
  height: calc(1.92rem - 4px);
  border-radius: 0.925rem;
  background-size: 50% 50%;
  background-color: white;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3897f0;
  background-color: #35c75a;
  border-color: #35c75a;
}
::v-deep
  .custom-switch.b-custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.18rem);
}
@media (max-width: 330px) {
  .leave-review-dpdwn >>> button {
    max-width: 120px !important;
  }
}
</style>
